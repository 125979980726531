<template>
  <div class="container">
    <div v-if="client">
      <div class="row mb-3">
        <div class="col-auto my-auto">
          <h5 class="mb-0 client-tab-heading">Online Programmes</h5>
        </div>
      </div>

      <!--  -->
      <hr class="my-3" />

      <busy :visible="loading" />

      <div v-if="!loading">
        <h5>Online Programmes</h5>

        <div v-if="clientProgrammeSchedule.length === 0">
          <div class="row mb-3">
            <div class="col mx-auto">
              <div class="card bg-light mx-auto mt-2">
                <div class="card-body text-center">
                  <i class="far fa-info-circle text-primary fa-3x"></i>
                  <p>
                    {{ client.name }} has not subscribed to any online
                    programmes yet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="card bg-light mb-3">
            <div class="card-body">
              <div class="card">
                <table class="table table-hover mb-0">
                  <thead>
                    <th scope="col" style="border-top: 0px">Programme</th>
                    <th scope="col" style="border-top: 0px">Start Date</th>
                    <th scope="col" style="border-top: 0px">Price</th>
                    <th scope="col" style="border-top: 0px">Payment Status</th>
                    <th scope="col" style="border-top: 0px"></th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="s in clientProgrammeSchedule"
                      class="cursor-pointer"
                      @click="viewSchedule(s.online_programme_schedule)"
                    >
                      <td>{{ s.title }}</td>
                      <td>{{ s.start_date | formatDate }}</td>
                      <td>{{ s.price_display }}</td>
                      <td class="text-capitalize">
                        {{ s.payment_status | formatStatus }}
                      </td>
                      <td>
                        <button
                          class="btn btn-sm btn-outline-danger float-end"
                          @click.stop="cancelClientSubscription(s)"
                        >
                          <i class="far fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <h5>My Active & Upcoming Online Programmes</h5>
        <div class="card bg-light mb-3">
          <div class="card-body">
            <div v-if="myProgrammeSchedule.length === 0" class="text-center">
              <i class="far fa-info-circle fa-3x text-info"></i>
              <p>You do not have any active or upcoming Online Programmes.</p>
            </div>
            <div v-else class="card">
              <table class="table table-hover mb-0">
                <thead>
                  <th scope="col" style="border-top: 0px">Programme</th>
                  <th scope="col" style="border-top: 0px">Start Date</th>
                  <th scope="col" style="border-top: 0px">Price</th>
                  <th scope="col" style="border-top: 0px">
                    Subscribed Clients
                  </th>
                  <th scope="col" style="border-top: 0px"></th>
                </thead>
                <tbody>
                  <tr v-for="schedule in myProgrammeSchedule">
                    <td>{{ schedule.title }}</td>
                    <td>{{ schedule.start_date | formatDate }}</td>
                    <td>{{ schedule.price_display }}</td>
                    <td>
                      {{ schedule.subscribed_client_count }}
                      <span v-if="schedule.max_subscribers > 0">
                        / {{ schedule.max_subscribers }}</span
                      >
                    </td>
                    <td>
                      <button
                        class="btn btn-sm btn-success"
                        :disabled="
                          schedule.subscribed_client_ids.includes(client.id)
                        "
                        @click.prevent="subscribeClient(schedule)"
                      >
                        Subscribe
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <subscribe-client
          :client="client"
          :schedule="modalSchedule"
          @subscribed="clientSubscribed"
        ></subscribe-client>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import SubscribeClient from "@/views/areas/clients/views/online-programmes-partials/SubscribeClient";
export default {
  data() {
    return {
      client: null,
      loading: true,
      clientProgrammeSchedule: [],
      myProgrammeSchedule: [],
      modalSchedule: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    fetchClientOnlineProgrammes() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/online-programme-schedules"
        )
        .then(({ data }) => {
          this.clientProgrammeSchedule = data;
          this.loading = false;
        });
    },
    fetchMyProgrammeSchedule() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/online-programme-schedule/api")
        .then(({ data }) => {
          this.myProgrammeSchedule = data;
          this.loading = false;
        });
    },
    subscribeClient(schedule) {
      this.modalSchedule = schedule;
      $("#subscribe-client-to-programme").modal("show");
    },
    clientSubscribed() {
      this.fetchClientOnlineProgrammes();
      this.fetchMyProgrammeSchedule();
      $("#subscribe-client-to-programme").modal("hide");
      this.modalSchedule = null;
    },
    viewSchedule(schedule) {
      this.$router.push({
        name: "OnlineProgrammeScheduleView",
        params: { id: schedule.id },
      });
    },
    cancelClientSubscription(s) {
      var confirmed = confirm(
        "Are you sure you wish to remove this client subscription? The corresponding order will be cancelled and they will no longer have access"
      );
      if (confirmed) {
        this.$axios
          .delete(
            process.env.VUE_APP_API_URL +
              "/clients/api/" +
              s.client_id +
              "/online-programme-schedules/" +
              s.id
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchClientOnlineProgrammes();
          });
      }
    },
    async fetchClientData() {
      const {
        data: { client },
      } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/clients/api/" + this.$route.params.id
      );

      this.client = client;
    },
  },
  async mounted() {
    this.$stash.clientInView = this.$route.params.id;
    await this.fetchClientData();
    this.fetchClientOnlineProgrammes();
    this.fetchMyProgrammeSchedule();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL [@] HH:mm A");
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    },
  },
  watch: {},
  components: { SubscribeClient },
};
</script>
