<template>
  <div class="emailModal">
    <div
      class="modal show"
      id="subscribe-client-to-programme"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div
            class="modal-header d-flex justify-space-between align-items-center"
          >
            <h5 class="mb-0">Subscribe Client to Online Programme</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div
            class="modal-body bg-white text-center"
            style="max-height: 75vh; overflow: scroll"
            v-if="schedule"
          >
            <!--  -->
            <h5>
              {{ schedule.online_programme.title }} -
              {{ schedule.start_date | formatDate }}
            </h5>

            <hr />

            <div class="row">
              <div class="col-6 mx-auto text-center">
                <currency-input
                  v-model="priceOverride"
                  currency="GBP"
                  locale="gb"
                  style="width: 150px"
                  class="form-control mx-auto"
                  placeholder="Enter a Price"
                  :min="0"
                />
              </div>
            </div>
          </div>

          <div class="modal-footer py-2 bg-white">
            <button
              type="button"
              class="btn btn-outline-secondary"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            >
              Cancel
            </button>
            <button class="btn btn-primary ms-auto" @click="subscribeClient">
              Subscribe Client to Programme
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CurrencyInput } from "vue-currency-input";
export default {
  props: ["client", "schedule"],
  data() {
    return {
      priceOverride: null,
    };
  },
  methods: {
    subscribeClient() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/clients/api/" +
            this.client.id +
            "/online-programme-schedules",
          {
            online_programme_schedule_id: this.schedule.id,
            price_override: this.priceOverride,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$emit("subscribed", data.client);
        });
    },
  },
  mounted() {},
  filters: {
    formatDate(date) {
      return moment(date).format("LL [@] HH:mm A");
    },
  },
  watch: {
    schedule(newVal) {
      if (newVal) {
        this.priceOverride = this.schedule.price;
      }
    },
  },
  components: {
    CurrencyInput,
  },
};
</script>

<style>
</style>
